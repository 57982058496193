/* react */
import { Dispatch, FC, memo, PropsWithChildren, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
/* store */
import { RootState, countryState, getCountryList, setCurrentCountry } from '@store'

/* utils */
import { classNames, randomKey } from '@utils'
/* types */
import { navbar, navItems, ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* componentes */
import { Button, NavLink, Select } from '..'
/* assets */
import { LogoCryplon } from '@assets'
import { FaArrowRight } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
/* styles */
import './index.scss'

const Navbar: FC<
    PropsWithChildren<{
        className?: string
        isNavbar?: boolean
        typeNav?: navbar
    }>
> = ({ className = '', typeNav = 'init' }) => {
    const dispatch: Dispatch<any> = useDispatch()

    const { country, currentCountry } = useSelector<RootState, countryState>(state => state.country)

    useEffect(() => {
        if (country.length <= 0) {
            dispatch(getCountryList())
        }
    }, [])

    /* lista de paises para componente select fase 2 */
    const currentCountries = country?.map(item => ({
        value: item.id,
        subtitle: item.code,
        title: item.name,
        icon: item.flag,
    }))

    const countriesIp = useMemo(() => {
        try {
            const code = currentCountries.find(c => c.value == 158)

            return code
        } catch (error) {
            console.log('error line 174')
        }
    }, [currentCountries])

    return (
        <header className={classNames('navbar', className)}>
            <div className="navbar__header">
                <i className="navbar__logo">
                    <LogoCryplon />
                </i>

                <nav>
                    <ul className="navbar__options">
                        {navItems.map(({ title, path }) => (
                            <li {...randomKey()}>
                                <NavLink
                                    to={path}
                                    className="navbar__option"
                                    activeClassName="navbar__option--active"
                                >
                                    <span>{title}</span>

                                    <div />
                                </NavLink>
                            </li>
                        ))}

                        {/* <li>
                            <a href="https://app.blocklon.com/" className="navbar__option">
                                Solicitar crédito
                            </a>
                        </li> */}
                    </ul>
                </nav>

                <nav>
                    <ul className="navbar__options">
                        <li className="navbar__option__select">
                            <Select
                                options={currentCountries}
                                beforeIcon={
                                    currentCountries?.find((c: any) => c.value == currentCountry)
                                        ?.icon
                                }
                                defaultText={countriesIp?.title}
                                onChange={(e: any) => {
                                    const value = Number(e.target.value)
                                    dispatch(setCurrentCountry(value))
                                }}
                                disabled={country.length <= 1 ? true : false}
                            />
                        </li>

                        <li>
                            <a href="https://app.blocklon.com/" target="_blank">
                                <Button
                                    role={ROLE_BUTTON_MAP.WARNING}
                                    variant={VARIANT_BUTTON_MAP.FILL}
                                >
                                    Ingresar <FaArrowRight />
                                </Button>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default memo(Navbar)
