import { FC, Suspense } from 'react'
import { BrowserRouter as Switch, Routes, Route } from 'react-router-dom'
import { randomKey } from '@utils'
import {
    AboutUs,
    ContactView,
    Home,
    QuestionsView,
    StepsCommerceView,
    StepsView,
    StepsWalletView,
} from '@views'

const AppRoutes: FC = () => {
    const routes = [
        { path: '/', component: <Home /> },
        { path: '/about-us', component: <AboutUs /> },
        { path: '/frequent-questions', component: <QuestionsView /> },
        { path: '/contact', component: <ContactView /> },
        { path: '/steps-credit', component: <StepsView /> },
        { path: '/steps-wallet', component: <StepsWalletView /> },
        { path: '/steps-commerce', component: <StepsCommerceView /> },
    ]

    return (
        <>
            <Switch>
                <Suspense fallback={<></>}>
                    <Routes>
                        {routes.map(({ path, component }) => (
                            <Route {...randomKey()} path={path} element={component} />
                        ))}
                    </Routes>
                </Suspense>
            </Switch>
        </>
    )
}
export default AppRoutes
