/* axios */
import { AxiosError } from 'axios'
/* services bases */
import { ApiService } from './constant.services'
/* models */
import { CountryResponse } from '@models'

export const countryServices = async () => {
    try {
        const response = await ApiService.get<CountryResponse>('country')

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}


