/* react */
import { memo, FC, PropsWithChildren } from 'react'
/* layouts */
import { BaseLayout, ScrollLayout } from '..'
import { useActive } from '@hooks/useActive.hook'
/* utils */
import { classNames } from '@utils'
/* types */
import { navbar, SCROLL_MAP } from '@types'
/* components */
import { Footer, MobileDropdown, Navbar } from '@components'
import MobileNavbar from '@components/MobileNavbar'
import { ToastContainer } from 'react-toastify'
import { MenuIcon } from '@assets/index'
/* styles */
import './index.scss'
import 'react-toastify/dist/ReactToastify.css'

const DashboardLayout: FC<
    PropsWithChildren<{
        className?: string
        title?: string
        typeNav?: navbar
        onChangeRight?: any
        onChangeLeft?: any
        iconRight?: any
        iconLeft?: any
        isBackground?: boolean
    }>
> = ({ children, className, title, typeNav, isBackground }) => {
    const [isModal, showModal, hideModal] = useActive()

    const deviceType = () => {
        const ua = navigator.userAgent
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 'tablet'
        } else if (
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return 'mobile'
        }
        return 'desktop'
    }

    return (
        <BaseLayout className={classNames('dashboard', className)}>
            <div className="dashboard__main">
                {/* <Sidebar className="dashboard__sidebar" /> */}

                <Navbar className="dashboard__sidebar" typeNav={typeNav} />

                <BaseLayout className="dashboard__content">
                    <MobileNavbar
                        className="dashboard__mobile-navbar"
                        title={title}
                        onChangeLeft={() => showModal()}
                        iconLeft={<MenuIcon />}
                        isBackground={isBackground}
                    />

                    <ScrollLayout
                        classNameContent={classNames(
                            'dashboard__container',
                            deviceType() === 'mobile'
                                ? 'dashboard__container--mobile'
                                : 'dashboard__container',
                            deviceType() === 'tablet'
                                ? 'dashboard__container--tablet'
                                : 'dashboard__container'
                        )}
                        scroll={SCROLL_MAP.Y}
                    >
                        <div className="dashboard__children">{children}</div>

                        <Footer />
                    </ScrollLayout>
                </BaseLayout>
            </div>

            <MobileDropdown isModal={isModal} hideModal={hideModal} />

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </BaseLayout>
    )
}

export default memo(DashboardLayout)
