import { memo, useEffect, useState } from 'react'
/* layouts */
import { DashboardLayout } from '@layouts'
/* utils */
import { classNames } from '@utils'
/* types */
import { SKELETON_TYPE_MAP } from '@types'
/* components */
import { SkeletonLoader } from '@components'
/* assets */
import {
    StepCredit2,
    StepCredit3,
    StepCredit4,
    StepCredit5,
    StepCredit6,
    StepPayment6,
    StepPayment8,
    StepPayment9,
    StepWalletContract,
} from '@assets'
/* styles */
import './index.scss'

const StepsWalletView = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])

    return (
        <DashboardLayout className="step-wallet-view" isBackground={true}>
            <main className="step-wallet-view__main">
                <section className="step-wallet-view__header">
                    <h1>
                        <strong>Paga con Blocklön</strong>
                    </h1>

                    <h2>Sigue estos pasos en tu navegador web</h2>
                </section>

                <section className="step-wallet-view__grid">
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit2}
                                    alt="step-register"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 1. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Haz clic en <strong>Crea tu cuenta ahora.</strong>
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className={classNames('step-wallet-view__grid', 'step-wallet-view__grid-bg')}
                >
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit3}
                                    alt="step-register"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 2. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Si aún no te registraste, selecciona el botón{' '}
                                        <strong>Registrarme ahora</strong> ingresando tus datos. Si
                                        ya lo estás, ingresa con tu usuario y contraseña.
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="step-wallet-view__grid">
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit4}
                                    alt="step-register"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 3. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        <strong>Ingresa los códigos</strong> que recibiste en tu
                                        correo electrónico y celular.
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className={classNames('step-wallet-view__grid', 'step-wallet-view__grid-bg')}
                >
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit5}
                                    alt="step-register"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 4. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Ya en tu perfil, selecciona la opción{' '}
                                        <strong>Wallet</strong>, haz clic en el botón solicitar
                                        ahora y escoge el tipo de <strong>persona jurídica.</strong>
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="step-wallet-view__grid">
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit6}
                                    alt="step-register"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 5. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Ingresa tus datos y sigue los pasos que te solicita la
                                        plataforma.
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section con bg */}
                <section
                    className={classNames('step-wallet-view__grid', 'step-wallet-view__grid-bg')}
                >
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepPayment8}
                                    alt="step-register"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 6. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Para continuar con la{' '}
                                        <strong>validación de identidad</strong> ,tómate una selfie
                                        o carga una foto tuya y los documentos necesarios.
                                    </h2>

                                    <br />

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Recibirás un correo con la{' '}
                                        <strong>confirmación de registro.</strong>
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section sin bg */}
                <section className="step-wallet-view__grid">
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-3"></div>

                        <div className="step-wallet-view__col-sm-8">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepWalletContract}
                                    alt="step-wallet-contract"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 7. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        <strong>Recibirás un correo electrónico </strong>
                                        para que firmes el contrato digital y puedas comenzar a
                                        utilizar nuestros servicios.
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section con bg */}
                <section
                    className={classNames('step-wallet-view__grid', 'step-wallet-view__grid-bg')}
                >
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepPayment6}
                                    alt="step-register"
                                    className="step-wallet-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 8. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Una vez cuentes con la aprobación vía mail, regresa a tu
                                        perfil y ya tendrás habilitada la opción wallet en el menú
                                        principal. Selecciona <strong> Recargar</strong>, allí
                                        podrás recargar las cripto que necesites, escoge la moneda
                                        que desees usar y haz la transferencia de tus cripto a la
                                        wallet que te indicamos.
                                    </h2>

                                    <br />

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Recibirás un mensaje confirmando que tu recarga ha sido
                                        exitosa y ahora podrás utilizar tus cripto para realizar el
                                        pago que desees.
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section con bg */}
                <section
                    className={classNames('step-wallet-view__grid', 'step-wallet-view__grid-bg')}
                >
                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__col-sm-2"></div>

                        <div className="step-wallet-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepPayment9}
                                    alt="step-payment"
                                    className={classNames(
                                        'step-wallet-view__img-fluid',
                                        'step-wallet-view__img-fluid-bg'
                                    )}
                                />
                            )}
                        </div>

                        <div className="step-wallet-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-wallet-view__row',
                            'step-wallet-view__row-align'
                        )}
                    >
                        <div className="step-wallet-view__container-fluid">
                            <div className="step-wallet-view__row">
                                <div className="step-wallet-view__col-sm-10">
                                    <h1 className="step-wallet-view__text-title">
                                        <strong>Paso 9. </strong>
                                    </h1>

                                    <h2 className="step-wallet-view__text-subtitle">
                                        Por último, selecciona el botón Pagar y escanea o ingresa el
                                        código que te compartió el negocio donde estás haciendo la
                                        compra y haz clic en confirmar.
                                    </h2>
                                </div>

                                <div className="step-wallet-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default memo(StepsWalletView)
