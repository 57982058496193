import { createSlice } from '@reduxjs/toolkit'
import { Country } from '@models'

// Define a type for the slice state
export interface countryState {
    country: Array<Country>
    currentCountry: number
    errorMessage: any
}

// Define the initial state using that type
const initialState: countryState = {
    country: [],
    currentCountry: 0,

    errorMessage: null,
}

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryList: (state = initialState, { payload }) => {
            ;(state.country = payload), (state.errorMessage = null)
        },
        setCurrentCountry: (state = initialState, { payload }) => {
            state.currentCountry = payload
        },
        deleteCountryList: state => {
            ;(state.country = []), (state.currentCountry = 0), (state.errorMessage = null)
        },
        countryListFail: (state = initialState, { payload }) => {
            ;(state.country = []),
                (state.currentCountry = 0),
                (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { countryListFail, deleteCountryList, setCountryList, setCurrentCountry } =
    countrySlice.actions
