// react
import { memo, PropsWithChildren, FC, useEffect } from 'react'
import { createPortal } from 'react-dom'
// utils
import { classNames } from '@utils'
import { MODAL_POSITION } from '@types'
// styles
import './index.scss'

/**
 * Custom layout to print content as modal overlay
 * @param className - style to be applied on 'div' wrapper
 * @param classNameContent - style to be applied on 'div' content wrapper
 * @param portal - where be the modal mounted
 * @param isModal - is current modal open?
 * @param position - current position to print the modal content
 * @param onClose - to close the modal
 * @param children - nested jsx
 * @param rest - wrapper props
 * @returns Layout with modal content support
 */
const ModalLayout: FC<PropsWithChildren<{
    className?: string
    classNameContent?: string
    node?: Element | DocumentFragment
    nodeId?: string
    isModal: boolean
    onClose?: any
    position?: MODAL_POSITION
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any
}>> = ({
    className = '',
    classNameContent = '',
    isModal = false,
    node,
    nodeId,
    position = MODAL_POSITION.CENTER,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose = () => {},
    children,
    ...rest
}) => {
    return createPortal(
        <>
            {isModal && (
                <div
                    className={classNames(
                        'modal-layout',
                        position || MODAL_POSITION[position],
                        className
                    )}
                    {...rest}
                >
                    <div className="modal-layout__overlay" onClick={onClose} />

                    <div
                        className={classNames(
                            'modal-layout__content',
                            classNameContent
                        )}
                    >
                        {children}
                    </div>
                </div>
            )}
        </>,
        node ?? (document.getElementById(nodeId ?? 'modal') as HTMLElement)
    )
}

export default memo(ModalLayout)
