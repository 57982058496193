// react
import { FC, memo, PropsWithChildren } from 'react'
// utils
import { classNames } from '@utils'
// types
import { SKELETON_TYPE_MAP } from '@types'
// styles
import './index.scss'

const SkeletonLoader: FC<PropsWithChildren<{
    type?: SKELETON_TYPE_MAP
    className?: string
    onClick?: () => void
}>> = ({ className, type = SKELETON_TYPE_MAP.TEXT, children, ...rest }) => {
    return (
        <div
            className={classNames('skeleton-loader', type || SKELETON_TYPE_MAP[type], className)}
            {...rest}
        >
            {children}
        </div>
    )
}

export default memo(SkeletonLoader)
