import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function useGoogleAnalytics(): [() => void, (category: any, event: any) => void] {
    const Location = useLocation()

    const InitAnalytics = () => {
        ReactGA.initialize('G-80JX7PKJL9')
    }

    const RegisterUserVisit = () => {
        const _pathname = Location.pathname.toLocaleLowerCase()
        const _search = Location.search.toLocaleLowerCase()

        InitAnalytics()

        // ReactGA.initialize('G-KKXBKDB97R')
        // ReactGA.pageview(_pathname + _search)

        // Send pageview with a custom path
        ReactGA.send({ hitType: 'pageview', page: _pathname + _search, title: _pathname })

        // window.gtag('config', 'GA_GUARDNFTPROJECT_SP_ID', {
        //     page_title: _pathname,
        //     page_path: _pathname + _search,
        // })
    }

    const CaptureUserEvent = (category = null, platform = null) => {
        if (!category || !platform) {
            return
        }

        // ReactGA.initialize('G-KKXBKDB97R')
        // ReactGA.event({ category, event })
        // const _pathname = Location.pathname.toLocaleLowerCase()
        // const _search = Location.search.toLocaleLowerCase()

        ReactGA.event({
            category: category,
            action: 'Click',
            label: platform,
        });

        // window.gtag('event', 'click', {
        //     category,
        //     event,
        //     page_path: _pathname + _search,
        // })
    }

    return [RegisterUserVisit, CaptureUserEvent]
}
