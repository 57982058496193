/* services */
import { countryServices } from '@services'
/* models */
import { CountryResponse } from '@models'
/* redux */
import {
    countryListFail,
    deleteCountryList,
    setCountryList,
    setCurrentCountry,
} from './countrySlices'

export const getCountryList = () => {
    return async (dispatch: any) => {
        const response = (await countryServices()) as CountryResponse

        if (response.error == true) return dispatch(countryListFail(response))

        const country = response.data?.find(c => c.id == 158)?.id

        dispatch(setCountryList(response.data))

        dispatch(setCurrentCountry(country))
    }
}

export const endLogoutCountry = () => {
    return async (dispatch: any) => {
        dispatch(deleteCountryList())
    }
}
