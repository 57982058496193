/* react */
import { memo, useEffect } from 'react'
/* hooks */
import { useGoogleAnalytics, useMinWidth } from '@hooks'
/* layouts */
import { DashboardLayout } from '@layouts'
/* utils */
import { classNames, matchBreakPoint } from '@utils'
/* types */
import { ROLE_BUTTON_MAP, TYPE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button } from '@components'
/* assets */
import { Card1, Card2, Card3, Landscape } from '@assets'
import { HiArrowNarrowDown, HiArrowNarrowRight } from 'react-icons/hi'
/* index.scss */
import './index.scss'

const AboutUsView = () => {
    const [breakPoint] = useMinWidth()

    const [RegisterVisit] = useGoogleAnalytics()

    useEffect(() => {
        RegisterVisit()
    }, [])

    const isMobile = matchBreakPoint('xl', breakPoint)

    return (
        <DashboardLayout className="about-view" isBackground={true}>
            <main className="about-view__main">
                <section className="about-view__grid">
                    <div className="about-view__container-fluid">
                        <div className="about-view__row">
                            <div className="about-view__col-sm-1"></div>

                            <div className="about-view__col-sm-10">
                                <div className="about-view__container">
                                    <h1 className="about-view__text-title">
                                        <strong>Sobre Nosotros </strong>
                                    </h1>

                                    <h2 className="about-view__text-subtitle">
                                        En Blocklön queremos <strong>transformar</strong> la forma
                                        en que manejas tu dinero y tus <strong>finanzas</strong>
                                    </h2>

                                    <div className="about-view__text-description">
                                        <span>
                                            Somos una plataforma 100% tecnológica que ofrece
                                            servicios financieros. Fundada por un grupo de expertos
                                            economistas y financieros con amplia experiencia en
                                            blockchain y criptomonedas. Hemos implementado una forma
                                            innovadora de realizar transacciones y acceder a
                                            créditos de forma inmediata aprovechando tus
                                            criptomonedas
                                        </span>

                                        <span>
                                            Nuestra visión es sencilla, pero ambiciosa: queremos que
                                            nuestros clientes tengan acceso a un futuro financiero
                                            más brillante y sin fronteras.
                                        </span>
                                    </div>

                                    <a
                                        href="https://app.blocklon.com/"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Button
                                            role={ROLE_BUTTON_MAP.WARNING}
                                            variant={VARIANT_BUTTON_MAP.FILL}
                                            forms={TYPE_BUTTON_MAP.BOX}
                                        >
                                            Empieza ahora
                                        </Button>
                                    </a>
                                </div>
                            </div>

                            {/* <div className="about-view__col-sm-3"></div> */}
                        </div>
                    </div>

                    <div className={classNames('about-view__row', 'about-view__row-bg')}>
                        <div className="about-view__col-sm-2"></div>

                        <div
                            className={classNames(
                                'about-view__col-sm-10',
                                'about-view__col-sm-10-align'
                            )}
                        >
                            <div className="about-view__card__container">
                                <article className="about-view__card__article">
                                    <img
                                        src={Landscape}
                                        alt="image"
                                        className="about-view__card__img"
                                    />
                                </article>
                            </div>
                        </div>

                        <div className="about-view__col-sm-1"></div>
                    </div>
                </section>

                <section className="about-view__content">
                    <h1>
                        Te brindamos la <strong> llave para potencializar tus criptomonedas</strong>
                    </h1>

                    <div className="about-view__content__grid">
                        <article className="about-view__content__article">
                            <i>
                                <Card1 />
                            </i>

                            <div className="about-view__info">
                                <span>
                                    Combinamos nuestra pasión por las criptomonedas con una sólida
                                    comprensión del sector financiero.
                                </span>
                            </div>
                        </article>

                        <article className="about-view__content__article">
                            <i>
                                <Card2 />
                            </i>

                            <div className="about-view__info">
                                <span>
                                    Cumplimos rigurosamente con toda la regulación vigente para
                                    operar con activos virtuales.
                                </span>
                            </div>
                        </article>

                        <article className="about-view__content__article">
                            <i>
                                <Card3 />
                            </i>

                            <div className="about-view__info">
                                <span>
                                    A través de la tecnología Blockchain aseguramos la transparencia
                                    de nuestras operaciones.
                                </span>
                            </div>
                        </article>
                    </div>
                </section>

                <section className="about-view__join">
                    <div className="about-view__join__row">
                        <div className="about-view__join__description">
                            <span className="about-view__join__subtitle">
                                <strong>Únete a nuestro equipo</strong>
                            </span>
                        </div>

                        <i className="about-view__join__icon">
                            {isMobile.under ? (
                                <HiArrowNarrowDown size={20} />
                            ) : (
                                <HiArrowNarrowRight size={20} />
                            )}
                        </i>

                        <div className="about-view__join__description">
                            <span className="about-view__join__title">
                                Cada uno de nuestros colaboradores hace parte de un grupo que esta
                                cambiando y <strong>revolucionando el sistema financiero.</strong>
                            </span>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default memo(AboutUsView)
