import { memo, useEffect, useState } from 'react'
/* hooks */
import { useGoogleAnalytics } from '@hooks'
/* layouts */
import { DashboardLayout } from '@layouts'
/* utils */
import { classNames } from '@utils'
/* types */
import { SKELETON_TYPE_MAP } from '@types'
/* components */
import { SkeletonLoader } from '@components'
/* assets */
import {
    StepCredit2,
    StepCredit3,
    StepCredit4,
    StepCredit5,
    StepCredit6,
    StepPayment8,
    StepBusiness7,
    StepBusiness8,
    StepBusiness9,
    StepBusiness10,
    StepWalletContract,
} from '@assets'
/* styles */
import './index.scss'

const StepsCommerceView = () => {
    const [RegisterVisit] = useGoogleAnalytics()

    useEffect(() => {
        RegisterVisit()
    }, [])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])

    return (
        <DashboardLayout className="step-commerce-view" isBackground={true}>
            <main className="step-commerce-view__main">
                <section className="step-commerce-view__header">
                    <h1>
                        <strong>Cobro con Blocklön</strong>
                    </h1>

                    <h2>Sigue estos pasos en tu navegador web</h2>
                </section>

                <section className="step-commerce-view__grid">
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit2}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 1. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Haz clic en <strong>Crea tu cuenta ahora.</strong>
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className={classNames(
                        'step-commerce-view__grid',
                        'step-commerce-view__grid-bg'
                    )}
                >
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit3}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 2. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Ingresa tus datos o usando tu cuenta de Gmail o Facebook.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="step-commerce-view__grid">
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit4}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 3. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        <strong>Ingresa los códigos</strong> que recibiste en tu
                                        correo electrónico y celular.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className={classNames(
                        'step-commerce-view__grid',
                        'step-commerce-view__grid-bg'
                    )}
                >
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit5}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 4. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Ya en tu perfil, selecciona la opción{' '}
                                        <strong>Cobra con Criptomonedas</strong>, haz clic en el
                                        botón solicitar ahora y escoge el tipo de{' '}
                                        <strong>persona jurídica.</strong>
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="step-commerce-view__grid">
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit6}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 5. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Ingresa tus datos y sigue los pasos que te solicita la
                                        plataforma.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section con bg */}
                <section
                    className={classNames(
                        'step-commerce-view__grid',
                        'step-commerce-view__grid-bg'
                    )}
                >
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepPayment8}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 6. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Para continuar con la{' '}
                                        <strong>validación de identidad</strong> ,tómate una selfie
                                        o carga una foto tuya y los documentos necesarios.
                                    </h2>

                                    <br />

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Recibirás un correo con la{' '}
                                        <strong>confirmación de registro.</strong>
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section sin bg */}
                <section className="step-commerce-view__grid">
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-3"></div>

                        <div className="step-commerce-view__col-sm-8">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepWalletContract}
                                    alt="step-wallet-contract"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 7. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        <strong>Recibirás un correo electrónico </strong>
                                        para que firmes el contrato digital y puedas comenzar a
                                        utilizar nuestros servicios.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section con bg */}
                <section
                    className={classNames(
                        'step-commerce-view__grid',
                        'step-commerce-view__grid-bg'
                    )}
                >
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepBusiness7}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 8. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Una vez cuentes con la aprobación vía mail, en tu perfil ya
                                        tendrás habilitada la opción para comenzar a realizar los
                                        cobros con cripto.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section sin bg */}
                <section className="step-commerce-view__grid">
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepBusiness8}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 9. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Lo primero que tienes que hacer es{' '}
                                        <strong>crear una sucursal </strong> o el número de
                                        sucursales que requieras. Para esto, ingresa a la opción{' '}
                                        <strong>sucursales </strong> en el menú principal y agrega
                                        los datos de ubicación de tu negocio o sucursal.
                                    </h2>

                                    <br />

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Ten en cuenta que si no tienes una sede física o eres un
                                        e-commerce puedes incluir la dirección desde donde operan.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section con bg */}
                <section
                    className={classNames(
                        'step-commerce-view__grid',
                        'step-commerce-view__grid-bg'
                    )}
                >
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepBusiness9}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 10. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Vuelve a la pantalla de cobro, escoge de la lista
                                        desplegable la sucursal que usarás, luego ingresa el monto
                                        en pesos mexicanos que vas a cobrar y haz clic en el botón
                                        Generar cobro.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* section sin bg */}
                <section className="step-commerce-view__grid">
                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__col-sm-2"></div>

                        <div className="step-commerce-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepBusiness10}
                                    alt="step-register"
                                    className="step-commerce-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-commerce-view__col-sm-1"></div>
                    </div>

                    <div
                        className={classNames(
                            'step-commerce-view__row',
                            'step-commerce-view__row-align'
                        )}
                    >
                        <div className="step-commerce-view__container-fluid">
                            <div className="step-commerce-view__row">
                                <div className="step-commerce-view__col-sm-10">
                                    <h1 className="step-commerce-view__text-title">
                                        <strong>Paso 11. </strong>
                                    </h1>

                                    <h2 className="step-commerce-view__text-subtitle">
                                        Por último,{' '}
                                        <strong>
                                            se generará un código QR, pídele a tu cliente que lo
                                            escanee y realice el pago
                                        </strong>{' '}
                                        siguiendo los pasos desde su celular en nuestra plataforma
                                        Blocklôn.
                                    </h2>
                                </div>

                                <div className="step-commerce-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default memo(StepsCommerceView)
