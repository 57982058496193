/* function validate regExp decimal */
export const validateDecimal = (e: any) => {
    /* e.key === '-' */
    const regex = new RegExp(/^\d{1,5}(\.)?$/)
    const regexD = new RegExp(/^\d{1,5}\.\d{1,8}$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        //const text = e.target.value.concat(e.key)
        const text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        if (!regex.test(text) && !regexD.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp text */
export const validateInteger = (e: any) => {
    /* e.key === '-' */
    const regex = new RegExp(/^\d{1,8}$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        //const text = e.target.value.concat(e.key)
        let text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        text = text.replaceAll(',', '')

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp number */
export const validateNumber = (e: any) => {
    const regex = new RegExp(/^[0-9]*$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'v', 'c']

    /*  const charCode = e.which || e.keyCode
    if (charCode < 48 || charCode > 57 || e.ctrlKey) {
        e.preventDefault() // previene la entrada de letras y caracteres especiales usando Ctrl
    } */

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        const text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp text */
export const validateText = (e: any) => {
    const regex = new RegExp(/^[a-zA-Z\u00C0-\u017F\s]+$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        const text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate email */
export const validationEmail = (v: any) => {
    const regex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-ñÑáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-ñÑáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(v)
}

/* function validate password */
export const validatePassword = (v: any) => {
    const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/)

    return regex.test(v)
}

/* function validate credit card */
export const cardValidation = (v: any) => {
    const MasterCard =
        /^5[1-5][0-9]{14}$|^2(?=2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/
    const AmericanExpress = /^3[47][0-9]{13}$/
    const Visa = /^4[0-9]{12}(?:[0-9]{3})?$/

    const validations = [MasterCard, AmericanExpress, Visa]

    for (let index = 0; index < validations.length; index++) {
        if (validations[index].test(v)) {
            return true
        }
    }
    return false
}

/* function validate CURP */
export const validateCURP = (curp: any) => {
    const curpRegex = /^[A-Z]{4}[0-9]{6}[HM][A-Z]{5}[0-9]{2}$/ // Expresión regular para validar CURP
    const upperCaseCURP = curp.toUpperCase()
    return curpRegex.test(upperCaseCURP) // Retorna true si el CURP es válido, o false si no lo es
}

/* function validate RFC */
export const validateRFC = (rfc: any) => {
    const rfcRegex = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/
    const upperCaseRFC = rfc.toUpperCase() // Convierte las letras a mayúsculas
    //console.log(upperCaseRFC)
    return rfcRegex.test(upperCaseRFC)
}

export const validateName = (e: any) => {
    const value = e.target.value.replace(/\s{2,}/g, ' ')
    e.target.value = value
}

export const upper = (v: any) =>
    new RegExp(/(.*[A-Z].*)/).test(v) || 'La contraseña debe tener al menos una mayúsculas'

export const lower = (v: any) =>
    new RegExp(/(.*[a-z].*)/).test(v) || 'La contraseña debe tener al menos una minúsculas'

export const special = (v: any) =>
    new RegExp(/(.*\w.*)/).test(v) || 'La contraseña debe tener al menos un carácter especial'

export const decimal = (v: any) =>
    new RegExp(/(.*\d.*)/).test(v) || 'La contraseña debe tener al menos un número'

export const RegExpText = (v: any) => new RegExp(/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ\s]+$/).test(v)

export const RegExpNumber = (v: any) => new RegExp(/^[0-9]+$/).test(v)
