/* react */
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
/* hooks */
import { useMinWidth } from '@hooks'
/* layouts */
import { DashboardLayout } from '@layouts'
/* utils */
import { matchBreakPoint } from '@utils'
/* types */
import { ROLE_BUTTON_MAP, TYPE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button } from '@components'
/* assets */
import {
    BillIcon,
    BitcoinIcon,
    CardLeft,
    CofreIcon,
    CompuIcon,
    EthereumIcon,
    FirmetiIcon,
    Icon1,
    Icon3,
    Icon4,
    Icon5,
    KeyIcon,
    KycIcon,
    LightningIcon,
    LockLeft,
    MetamaskIcon,
    MoneyIcon,
    PayCashIcon,
    PigIcon,
    StpIcon,
    WalletConnectIcon,
} from '@assets'
import { FaArrowRight } from 'react-icons/fa'
/* styles */
import './index.scss'

const Home = () => {
    const navigate = useNavigate()

    // const [, captureUserEvent] = useGoogleAnalytics()

    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('xl', breakPoint)

    const coins = [
        { icons: <StpIcon /> },
        { icons: <PayCashIcon /> },
        { icons: <KycIcon /> },
        { icons: <FirmetiIcon /> },
    ]

    const wallets = [
        { icon: <BitcoinIcon />, title: 'Bitcoin' },
        { icon: <EthereumIcon />, title: 'Ethereum' },
        { icon: <MetamaskIcon />, title: 'Metamask' },
        { icon: <WalletConnectIcon />, title: 'WalletConnect' },
    ]

    // const handleClick = () => {
    //     captureUserEvent('Login', 'App Blocklon')
    //     // ReactGA.event({
    //     //     category: 'Login',
    //     //     action: 'Click',
    //     //     label: 'App Blocklon',
    //     // })
    // }

    return (
        <DashboardLayout className="home-view" isBackground={true}>
            <main className="home-view__main">
                <section>
                    <section className="home-view__section-1">
                        <div className="home-view__section-1__section">
                            <section className="home-view__section-1__content">
                                <div className="home-view__section-1__information">
                                    <div className="home-view__section-1__information__title">
                                        <div>
                                            <h1>Productos financieros basados en criptomonedas</h1>

                                            <h3>
                                                Solicita tu crédito con colateral en criptomonedas,
                                                paga con tus criptomonedas de forma fácil y segura
                                                en tu comercio favorito o incrementa las ventas de
                                                tu negocio con nuevos canales de pago para tus
                                                clientes. ​
                                            </h3>

                                            <a
                                                href="https://app.blocklon.com/"
                                                /* onClick={handleClick} */
                                            >
                                                <Button
                                                    role={ROLE_BUTTON_MAP.WARNING}
                                                    variant={VARIANT_BUTTON_MAP.FILL}
                                                    forms={TYPE_BUTTON_MAP.BOX}
                                                    rightIcon={<FaArrowRight />}
                                                >
                                                    Crea tu cuenta ahora
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="home-view__section-1__icons"></section>
                        </div>
                    </section>

                    <section className="home-view__banner-coins">
                        <div>
                            <h3>Nuestros socios institucionales</h3>

                            <div className="home-view__banner-coins__coin">
                                {coins.map((coin, index: any) => (
                                    <i key={index}>{coin.icons}</i>
                                ))}
                            </div>
                        </div>
                    </section>
                </section>

                {isMobile.under ? <></> : <article />}

                <section>
                    <section className="home-view__section-2">
                        <div className="home-view__section-2__section">
                            <section className="home-view__section-2__phone"></section>

                            <div className="home-view__section-2__content">
                                <div className="home-view__section-2__information">
                                    <div className="home-view__section-2__information__title">
                                        <div>
                                            <h3>CRÉDITO</h3>

                                            <h1>
                                                Solicita tu <span>Crédito en pesos méxicanos</span>{' '}
                                                respaldado en criptomonedas y disfrútalo ahora mismo
                                                en tu cuenta bancaria
                                            </h1>

                                            <h2>
                                                Es un proceso sencillo, fácil y sin complicaciones,
                                                obtén tu préstamo en tiempo record, y disfruta de
                                                dinero sin vender tu criptomoneda.
                                            </h2>

                                            {/* <a href="https://app.blocklon.com/"></a> */}
                                            <Button
                                                role={ROLE_BUTTON_MAP.ACCENT}
                                                variant={VARIANT_BUTTON_MAP.FILL}
                                                forms={TYPE_BUTTON_MAP.BOX}
                                                rightIcon={<FaArrowRight />}
                                                onClick={() => navigate('/steps-credit')}
                                            >
                                                ¿Cómo solicito mi crédito?
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <article />
                <article />

                <section>
                    <section className="home-view__section-2-1">
                        <div className="home-view__section-2-1__section">
                            <div className="home-view__section-2-1__content">
                                <div className="home-view__section-2-1__information">
                                    <div className="home-view__section-2-1__information__title">
                                        <div>
                                            <h3>Wallet</h3>

                                            <h1>Tus criptomonedas son tu nuevo método de pago</h1>

                                            <h2>
                                                Regístrate y comienza a pagar en tus comercios
                                                preferidos. Ahora será muy fácil pagar en tus
                                                tiendas de preferencia con tus criptomonedas,
                                                rápido, económico y seguro.
                                            </h2>

                                            {/* <a href="https://app.blocklon.com/"></a> */}
                                            <Button
                                                role={ROLE_BUTTON_MAP.ACCENT}
                                                variant={VARIANT_BUTTON_MAP.FILL}
                                                forms={TYPE_BUTTON_MAP.BOX}
                                                rightIcon={<FaArrowRight />}
                                                onClick={() => navigate('/steps-wallet')}
                                            >
                                                ¿Cómo realizo un pago?
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="home-view__section-2-1__phone"></section>
                        </div>
                    </section>
                </section>

                {/* <article /> */}

                <section>
                    <section className="home-view__section-2-2">
                        <div className="home-view__section-2-2__section">
                            <div className="home-view__section-2-2__content">
                                <div className="home-view__section-2-2__information">
                                    <div className="home-view__section-2-2__information__title">
                                        <div>
                                            <h3>FOR BUSINESS</h3>

                                            <h1>
                                                Ofrece nuevos canales de pago con criptomonedas a
                                                tus usuarios y aumenta tus ventas
                                            </h1>

                                            <h2>
                                                No tienes porqué preocuparte, recibe en pesos en tu
                                                cuenta bancaria y Blocklön realiza lo demás.
                                            </h2>

                                            {/* <a href="https://app.blocklon.com/"></a> */}
                                            <Button
                                                role={ROLE_BUTTON_MAP.ACCENT}
                                                variant={VARIANT_BUTTON_MAP.FILL}
                                                forms={TYPE_BUTTON_MAP.BOX}
                                                rightIcon={<FaArrowRight />}
                                                onClick={() => navigate('/steps-commerce')}
                                            >
                                                ¿Cómo realizo un cobro?
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="home-view__section-2-2__phone"></section>
                        </div>
                    </section>
                </section>

                <article />

                <div className="home-view__banner">
                    <div className="home-view__banner__main">
                        <span>
                            <span></span>
                        </span>

                        <h1>Descarga la app en tu celular</h1>

                        <div className="home-view__banner__store">
                            <a href="https://appgallery.huawei.com/app/C110496277">
                                <img
                                    src="https://defi-truegamesapi.sfo3.cdn.digitaloceanspaces.com/branding/appgallery-badget.svg"
                                    alt="GalleryStore"
                                />
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.suwanneeagle.blocklon&pli=1">
                                <img
                                    src="https://defi-truegamesapi.sfo3.cdn.digitaloceanspaces.com/branding/playstore-badget.svg"
                                    alt="PlayStore"
                                />
                            </a>

                            <a href="">
                                <img
                                    src="https://defi-truegamesapi.sfo3.cdn.digitaloceanspaces.com/branding/appstore-badget.svg"
                                    alt="AppStore"
                                />
                            </a>
                        </div>
                    </div>

                    <img
                        src="https://defi-truegamesapi.sfo3.cdn.digitaloceanspaces.com/web/AdobeStock_422203888.png"
                        className="home-view__banner__icon"
                        alt="icon"
                    />
                </div>

                <section>
                    <section className="home-view__section-4">
                        <div className="home-view__section-4__title">
                            <h1>Blocklön es una plataforma de servicios financieros</h1>

                            <span>
                                Aplicamos las ventajas de la tecnología blockchain para supervisar
                                tu colateral, ofreciendo máxima confianza, seguridad y
                                transparencia.
                            </span>
                        </div>

                        <div className="home-view__section-4__container">
                            <section className="home-view__section-4__container__section-1">
                                <div className="home-view__section-4__container__card">
                                    <i>
                                        <CardLeft />
                                    </i>

                                    <div>
                                        <h3>Una única Wallet para tus activos</h3>

                                        <span>
                                            En Blocklön asignamos una wallet única para cada
                                            crédito. ¡Nunca la perderás de vista!.
                                        </span>
                                    </div>
                                </div>

                                <div className="home-view__section-4__container__card">
                                    <i>
                                        <KeyIcon />
                                    </i>

                                    <div>
                                        <h3>Custodia de activos</h3>

                                        <span>
                                            No pierdes de vista tus activos Las criptomonedas se
                                            almacenan en coldwallets offline únicas, a las que
                                            puedes darle seguimiento en todo momento.
                                        </span>
                                    </div>
                                </div>

                                <div className="home-view__section-4__container__card">
                                    <i>
                                        <CompuIcon />
                                    </i>

                                    <div>
                                        <h3>Vamos mucho más allá que una prueba de reservas</h3>

                                        <span>
                                            Ya no necesitas una prueba de reservas. Con nosotros,
                                            puedes supervisar tu colateral de manera continua, las
                                            24 horas del día, los 7 días de la semana.
                                        </span>
                                    </div>
                                </div>
                            </section>

                            <section className="home-view__section-4__container__section-2">
                                <div className="home-view__section-4__container__card">
                                    <i>
                                        <LockLeft />
                                    </i>

                                    <div>
                                        <h3>Máxima seguridad</h3>

                                        <span>
                                            En Blocklôn protegemos y valoramos tus criptoactivos con
                                            la máxima seguridad.
                                        </span>

                                        <span>Nunca usamos tus criptoactivos.</span>
                                    </div>
                                </div>

                                <div className="home-view__section-4__container__card">
                                    <i>
                                        <MoneyIcon />
                                    </i>

                                    <div>
                                        <h3>Devolución efectiva</h3>

                                        <div>
                                            <span>
                                                El proceso de devolución es automático y las tarifas
                                                de transacción dependen de la red:
                                            </span>

                                            <span>
                                                <i>
                                                    <LightningIcon />
                                                </i>{' '}
                                                BTC: Lightning Network{' '}
                                            </span>
                                            <span>
                                                <i>
                                                    <EthereumIcon />
                                                </i>{' '}
                                                ETH: ERC20{' '}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </section>

                <section>
                    <section className="home-view__banner-clock">
                        <div>
                            <i className="home-view__banner-clock__icon">
                                <PigIcon />
                            </i>

                            <div className="home-view__banner-clock__title">
                                <h3>Aprovecha tu criptomoneda</h3>

                                <span>
                                    No vendas tus activos, saca ventaja de tus inversiones con{' '}
                                    <span className="home-view__banner-clock__title--link">
                                        Blocklön.
                                    </span>
                                </span>
                            </div>

                            <a href="https://app.blocklon.com/" /* onClick={handleClick} */>
                                <Button
                                    role={ROLE_BUTTON_MAP.SUCCESS}
                                    variant={VARIANT_BUTTON_MAP.FILL}
                                >
                                    Crea tu cuenta ahora <FaArrowRight />
                                </Button>
                            </a>
                        </div>
                    </section>

                    <section className="home-view__section-4-1">
                        <div className="home-view__section-4-1__container">
                            <div className="home-view__section-4-1__title">
                                <h1>Wallets & Criptomonedas soportadas</h1>

                                <span>
                                    Explore la creciente lista de billeteras admitidas. Más muy
                                    pronto.
                                </span>
                            </div>

                            <div className="home-view__section-4-1__wallets">
                                {wallets.map(({ icon, title }, index) => (
                                    <div key={index} className="home-view__section-4-1__wallet">
                                        <i className="home-view__section-4-1__wallet__icon">
                                            {icon}
                                        </i>

                                        <span>{title}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </section>

                <section>
                    <section className="home-view__section-5">
                        <section className="home-view__section-5--bg">
                            <i className="home-view__section-5__icon">
                                <BillIcon />
                            </i>

                            <div className="home-view__section-5__title">
                                <h1>Las mejores practicas de seguridad informática</h1>

                                <span>
                                    Nuestros procesos son 100% transparentes y verificables, nunca
                                    pierdes de vista tus activos y estos siempre están visibles en
                                    el app.
                                </span>
                            </div>

                            <div className="home-view__section-5__container">
                                <section className="home-view__section-5__container__section-2">
                                    <div className="home-view__section-5__container__card">
                                        <i className="home-view__section-5__container__card__icon">
                                            <Icon4 />
                                        </i>

                                        <div>
                                            <h3>
                                                Protección Integral con Encriptación a Nivel de
                                                Filesystem.
                                            </h3>

                                            <span>
                                                Nuestros sistemas utilizan una sólida capa de
                                                seguridad para tus datos sensibles. Implementamos
                                                una encriptación robusta a nivel de sistema de
                                                archivos, lo que significa que tus datos almacenados
                                                están completamente cifrados, proporcionando una
                                                protección adicional en caso de acceso no
                                                autorizado.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="home-view__section-5__container__card">
                                        <i className="home-view__section-5__container__card__icon">
                                            <Icon3 />
                                        </i>

                                        <div>
                                            <h3>
                                                Gestión de Seguridad Integral para Datos
                                                Confidenciales.
                                            </h3>

                                            <span>
                                                A través de nuestro socio de custodia Hashicorp
                                                Vault, Blocklön cuenta con un sistema avanzado de
                                                almacenamiento de secretos y contraseñas requeridas
                                                para las encriptaciones de los datos almacenados en
                                                nuestra plataforma, lo que garantiza la máxima
                                                seguridad para los datos confidenciales y la
                                                protección de nuestra información más sensible.
                                            </span>
                                        </div>
                                    </div>
                                </section>

                                <section className="home-view__section-5__container__section-2">
                                    <div className="home-view__section-5__container__card">
                                        <i className="home-view__section-5__container__card__icon">
                                            <Icon5 />
                                        </i>
                                        <div>
                                            <h3>Cold Wallet Offline</h3>

                                            <span>
                                                En nuestros sistemas, la seguridad de tus
                                                criptoactivos es una prioridad absoluta. Hemos
                                                implementado un innovador sistema de almacenamiento,
                                                manteniendo tus activos digitales fuera de línea y a
                                                salvo de cualquier amenaza en línea para garantizar
                                                que tus criptoactivos estén protegidos de manera
                                                inquebrantable contra posibles ciberataques o
                                                intrusiones.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="home-view__section-5__container__card">
                                        <i className="home-view__section-5__container__card__icon">
                                            <Icon1 />
                                        </i>

                                        <div>
                                            <h3>Respaldo y Recuperación de Datos</h3>

                                            <span>
                                                Contamos con servicios periódicos para el respaldo
                                                de nuestra información, rotación de llaves de acceso
                                                y contraseñas.
                                            </span>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </section>
                </section>

                {isMobile.under ? <></> : <article />}

                <section>
                    <section className="home-view__section-6">
                        <i className="home-view__section-6__cofre">
                            <CofreIcon />
                        </i>

                        <div className="home-view__banner-credits">
                            <section className="home-view__banner-credit">
                                <div>
                                    <div className="home-view__banner-credit__title">
                                        <h3>
                                            Prueba y solicita ahora tu próximo crédito respaldado
                                            por criptomonedas
                                        </h3>

                                        <a href="https://app.blocklon.com/" /* onClick={handleClick} */>
                                            <Button
                                                role={ROLE_BUTTON_MAP.WARNING}
                                                variant={VARIANT_BUTTON_MAP.FILL}
                                            >
                                                Crea tu cuenta ahora
                                            </Button>
                                        </a>
                                    </div>

                                    <section className="home-view__banner-credit__phone"></section>

                                    {isMobile.under && (
                                        <section className="home-view__banner-credit__phone-1"></section>
                                    )}
                                </div>
                            </section>
                        </div>
                    </section>
                </section>

                <article />
            </main>
        </DashboardLayout>
    )
}

export default memo(Home)
