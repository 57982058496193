import { memo, useEffect, useState } from 'react'
/* layouts */
import { DashboardLayout } from '@layouts'
/* utils */
import { classNames } from '@utils'
/* types */
import { SKELETON_TYPE_MAP } from '@types'
/* components */
import { SkeletonLoader } from '@components'
/* assets */
import {
    StepCredit10,
    StepCredit11,
    StepCredit12,
    StepCredit13,
    StepCredit2,
    StepCredit3,
    StepCredit4,
    StepCredit5,
    StepCredit6,
    StepCredit7,
    StepCredit8,
    StepPayment8,
} from '@assets'
/* styles */
import './index.scss'

const StepsView = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])

    return (
        <DashboardLayout className="step-view" isBackground={true}>
            <main className="step-view__main">
                <section className="step-view__header">
                    <h1>
                        ¿Cómo solicito <strong>mi crédito?</strong>{' '}
                    </h1>

                    <h2>Sigue estos pasos en tu navegador web</h2>
                </section>

                {/* init */}
                <section className="step-view__grid">
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit2}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 1. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Haz clic en <strong>Crea tu cuenta ahora.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* init */}
                <section className={classNames('step-view__grid', 'step-view__grid-bg')}>
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit3}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 2. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Si aún no te registraste, selecciona el botón{' '}
                                        <strong>Registrarme ahora</strong> ingresando tus datos. Si
                                        ya lo estás, ingresa con tu usuario y contraseña.
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* init */}
                <section className="step-view__grid">
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit4}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 3. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        <strong>Ingresa los códigos</strong> que recibiste en tu
                                        correo electrónico y celular.
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* init */}
                <section className={classNames('step-view__grid', 'step-view__grid-bg')}>
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit5}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 4. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Ya en tu perfil, selecciona la opción{' '}
                                        <strong>créditos</strong>, haz clic en el botón solicitar
                                        ahora y escoge el tipo de <strong>persona jurídica.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="step-view__grid">
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit6}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 5. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Ingresa tus datos y sigue los pasos que te solicita la
                                        plataforma.
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={classNames('step-view__grid', 'step-view__grid-bg')}>
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepPayment8}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 6. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Para continuar con la{' '}
                                        <strong>validación de identidad</strong> ,tómate una selfie
                                        o carga una foto tuya y los documentos necesarios.
                                    </h2>

                                    <br />

                                    <h2 className="step-view__text-subtitle">
                                        Recibirás un correo con la{' '}
                                        <strong>confirmación de registro.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="step-view__grid">
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit7}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 7. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Una vez registrado, en tu perfil tendrás habilitada la
                                        opción para solicitar tu crédito en el menú principal.
                                        Ingresa a <strong>crédito </strong> y haz clic en{' '}
                                        <strong>solicitar ahora.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={classNames('step-view__grid', 'step-view__grid-bg')}>
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit8}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 8. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Ahora podrás <strong>simular y solicitar tu crédito. </strong>
                                        Ingresa el valor de tu colateral en cripto y podrás ver
                                        cuánto dinero en pesos mexicanos te podriamos prestar. O
                                        ajusta en la barra el valor en pesos mexicanos que requieres
                                        y verás automåticamente el monto del colateral que
                                        necesitarias.
                                    </h2>

                                    <br />

                                    <h2 className="step-view__text-subtitle">
                                        Elige el plazo que desees y revisa tu pago mensual, la tasa
                                        de interés y todos los detalles de tu crédito. Si estás de
                                        acuerdo, haz clic en <strong>solicitar ahora.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* init */}
                <section className="step-view__grid">
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit10}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 9. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Ahora debes hacer la{' '}
                                        <strong>transferencia del colateral</strong>, para ellos
                                        tienes <strong>dos opciones:</strong> conectar tu wallet
                                        directamente o hacer la transferencia de las cripto a la
                                        dirección que te indicamos.
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* final */}
                <section className={classNames('step-view__grid', 'step-view__grid-bg')}>
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit11}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 10. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Sigue los pasos de la opción que elijas y espera unos
                                        minutos mientras se confirma la transacción. Una vez
                                        recibida, verás un mensaje y{' '}
                                        <strong>recibirás un correo con la confirmación.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* final */}
                <section className="step-view__grid">
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit12}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 11. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Ingresa el número de la CLABE bancaria donde quieres recibir
                                        el dinero y haz clic en <strong>validar cuenta.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* init */}
                <section className={classNames('step-view__grid', 'step-view__grid-bg')}>
                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__col-sm-2"></div>

                        <div className="step-view__col-sm-10">
                            {loading ? (
                                <SkeletonLoader
                                    className="step-view__img-fluid--skeleton"
                                    type={SKELETON_TYPE_MAP.BOX}
                                />
                            ) : (
                                <img
                                    src={StepCredit13}
                                    alt="step-register"
                                    className="step-view__img-fluid"
                                />
                            )}
                        </div>

                        <div className="step-view__col-sm-1"></div>
                    </div>

                    <div className={classNames('step-view__row', 'step-view__row-align')}>
                        <div className="step-view__container-fluid">
                            <div className="step-view__row">
                                <div className="step-view__col-sm-10">
                                    <h1 className="step-view__text-title">
                                        <strong>Paso 12. </strong>
                                    </h1>

                                    <h2 className="step-view__text-subtitle">
                                        Por último, enviaremos a tu correo electrónico el contrato
                                        para que incluyas tu firma digital y una vez completado este
                                        proceso <strong>recibirás tu dinero en minutos.</strong>
                                    </h2>
                                </div>

                                <div className="step-view__col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default memo(StepsView)
