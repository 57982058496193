import { useState, useMemo, memo, PropsWithChildren, forwardRef } from 'react'
// import util
import { randomKey, classNames } from '@utils'
/* types */
import { ROLE_PARENT_MAP, VARIANT_PARENT_MAP } from '@types'
/* assets */
import { FaEye, FaEyeSlash } from 'react-icons/fa'
/* styles */
import './index.scss'

const TextField = forwardRef<
    HTMLInputElement,
    PropsWithChildren<{
        label?: string
        type?: string
        className?: string
        placeholder?: string
        errorMessage?: string
        hint?: string
        isHintReserved?: boolean
        role?: ROLE_PARENT_MAP
        variant?: VARIANT_PARENT_MAP
        disabled?: boolean
        keepBorder?: boolean
        isFit?: boolean
        isInteractive?: boolean
        beforeIcon?: any
        beforeClassName?: string
        afterIcon?: any
        afterClassName?: string
        dateProps?: any
        formatedDate?: any
        isBackground?: boolean
        afterContent?: any
        [x: string]: any
    }>
>(
    (
        {
            label = '',
            type = 'text',
            className,
            placeholder = 'Escribe aquí',
            errorMessage,
            hint,
            isHintReserved = false,
            role = ROLE_PARENT_MAP.NONE,
            variant = VARIANT_PARENT_MAP.TEXT,
            disabled,
            keepBorder = false,
            isFit = false,
            isInteractive = false,
            beforeIcon,
            beforeClassName,
            afterIcon,
            afterClassName,
            dateProps,
            formatedDate,
            isBackground = false,
            afterContent,
            ...rest
        },
        ref
    ) => {
        // random id to assing to input field & make reference from label
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const idFieldText: string = useMemo((): string => randomKey().key, [])

        const [showPassword, setShowPassword] = useState(false)

        return (
            <>
                <fieldset
                    className={classNames(
                        'text-field',
                        errorMessage ? ROLE_PARENT_MAP.DANGER : role || ROLE_PARENT_MAP[role],
                        isFit ? 'text-field--fit' : '',
                        type === 'file' ? 'text-field--file' : '',
                        type === 'checkbox' ? 'text-field--checkbox' : '',
                        type === 'switch' ? 'text-field--switch' : '',
                        className
                    )}
                    disabled={disabled}
                >
                    {(label || type === 'switch') && (
                        <label
                            className={classNames(
                                'text-field__label',
                                type === 'switch' ? 'text-field__label--switch' : ''
                            )}
                            htmlFor={idFieldText}
                        >
                            {type === 'switch' && (
                                <>
                                    <input
                                        type="checkbox"
                                        id={idFieldText}
                                        autoComplete="off"
                                        {...rest}
                                        ref={ref}
                                    />
                                    <span />
                                </>
                            )}

                            {label && <span>{label}</span>}
                        </label>
                    )}
                    {/* ...rest assing other props passed into component invoke */}

                    <div
                        className={classNames(
                            'text-field__content',
                            type === 'switch' ? 'text-field__content--hidden' : '',
                            keepBorder ? 'text-field__content--keep' : '',
                            isBackground ? 'text-field__content--background' : '',
                            disabled ? 'text-field__content--disabled' : '',
                            className
                        )}
                    >
                        {beforeIcon && (
                            <i
                                className={classNames(
                                    'text-field__content-bi',
                                    isBackground ? 'text-field__content--background-bi' : ''
                                )}
                            >
                                {beforeIcon}
                            </i>
                        )}

                        <input
                            id={idFieldText}
                            placeholder={placeholder}
                            className={classNames(
                                type === 'date' ? 'text-field__content__date' : '',
                                type === 'file' ? 'text-field__content__file' : '',
                                type === 'select-modal' ? 'text-field__content__select' : '',
                                type === 'switch' ? 'text-field__content__switch' : '',
                            )}
                            type={
                                type === 'password' && showPassword
                                    ? 'text'
                                    : type === 'select-modal'
                                    ? 'text'
                                    : type === 'switch'
                                    ? 'checkbox'
                                    : type
                            }
                            disabled={type === 'select-modal' || type === 'switch'}
                            autoComplete="off"
                            autoFocus={false}
                            ref={ref}
                            {...rest}
                        />

                        {type === 'password' && (
                            <i
                                className="text-field__content-pass"
                                onClick={() => {
                                    !disabled ? setShowPassword(!showPassword) : undefined
                                }}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </i>
                        )}

                        {afterIcon && (
                            <div
                                className={classNames(
                                    'text-field__content-ai',
                                    'text-field__content-ai--interactive',
                                    isBackground ? 'text-field__content--background-ai' : ''
                                )}
                            >
                                {afterIcon}
                            </div>
                        )}

                        {afterContent && afterContent()}
                    </div>

                    {errorMessage ? (
                        <span className="text-field__hint">{errorMessage}</span>
                    ) : hint ? (
                        <span className="text-field__hint">{hint}</span>
                    ) : (
                        isHintReserved && <span className="text-field__reserved" />
                    )}
                </fieldset>
            </>
        )
    }
)

export default memo(TextField)
