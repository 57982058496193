import { useState, useMemo, memo, PropsWithChildren, forwardRef } from 'react'
// import util
import { randomKey, classNames } from '@utils'
/* types */
import { ROLE_TEXTAREA_MAP, VARIANT_TEXTAREA_MAP } from '@types'
/* assets */
import { FaEye, FaEyeSlash } from 'react-icons/fa'
/* styles */
import './index.scss'

const TextareaField = forwardRef<
    HTMLTextAreaElement,
    PropsWithChildren<{
        label?: string

        className?: string
        placeholder?: string
        errorMessage?: string
        hint?: string
        isHintReserved?: boolean
        role?: ROLE_TEXTAREA_MAP,
        variant?: VARIANT_TEXTAREA_MAP
        disabled?: boolean
        keepBorder?: boolean
        isFit?: boolean
        isInteractive?: boolean
        beforeIcon?: any
        beforeClassName?: string
        afterIcon?: any
        afterClassName?: string
        isBackground?: boolean
        afterContent?: any
        [x: string]: any
    }>
>(
    (
        {
            label = '',
            className,
            placeholder = 'Escribe aquí',
            errorMessage,
            hint,
            isHintReserved = false,
            role = ROLE_TEXTAREA_MAP.NONE,
            variant = VARIANT_TEXTAREA_MAP.TEXT,
            disabled,
            keepBorder = false,
            isFit = false,
            isInteractive = false,
            beforeIcon,
            beforeClassName,
            afterIcon,
            afterClassName,
            isBackground = false,
            afterContent,
            ...rest
        },
        ref
    ) => {
        // random id to assing to input field & make reference from label
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const idFieldText: string = useMemo((): string => randomKey().key, [])

        const [showPassword, setShowPassword] = useState(false)

        return (
            <>
                <fieldset
                    className={classNames(
                        'textarea-field',
                        errorMessage ? ROLE_TEXTAREA_MAP.DANGER : role || ROLE_TEXTAREA_MAP[role],
                        isFit ? 'textarea-field--fit' : '',
                        className
                    )}
                    disabled={disabled}
                >
                    {label && (
                        <label className={classNames('textarea-field__label')} htmlFor={idFieldText}>
                            {label && <span>{label}</span>}
                        </label>
                    )}
                    {/* ...rest assing other props passed into component invoke */}

                    <div
                        className={classNames(
                            'textarea-field__content',

                            keepBorder ? 'textarea-field__content--keep' : '',
                            isBackground ? 'textarea-field__content--background' : '',
                            disabled ? 'textarea-field__content--disabled' : '',
                            className
                        )}
                    >
                        {beforeIcon && (
                            <i
                                className={classNames(
                                    'textarea-field__content-bi',
                                    isBackground ? 'textarea-field__content--background-bi' : ''
                                )}
                            >
                                {beforeIcon}
                            </i>
                        )}

                        <textarea
                            id={idFieldText}
                            placeholder={placeholder}
                            autoComplete="off"
                            autoFocus={false}
                            ref={ref}
                            {...rest}
                        />

                        {afterIcon && (
                            <div
                                className={classNames(
                                    'textarea-field__content-ai',
                                    'textarea-field__content-ai--interactive',
                                    isBackground ? 'textarea-field__content--background-ai' : ''
                                )}
                            >
                                {afterIcon}
                            </div>
                        )}

                        {afterContent && afterContent()}
                    </div>

                    {errorMessage ? (
                        <span className="textarea-field__hint">{errorMessage}</span>
                    ) : hint ? (
                        <span className="textarea-field__hint">{hint}</span>
                    ) : (
                        isHintReserved && <span className="textarea-field__reserved" />
                    )}
                </fieldset>
            </>
        )
    }
)

export default memo(TextareaField)
