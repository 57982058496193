import React, { FC, memo } from 'react'
import * as iconsMd from 'react-icons/md'
import * as iconsFa from 'react-icons/fa'

export type IconType = keyof typeof iconsMd | keyof typeof iconsFa
export interface IIcon {
    icon: IconType,
    className ?: string
}

const iconsLib = {
    ...iconsMd,
    ...iconsFa,
}

const Icon: FC<IIcon> = ({ icon }: IIcon) => {
    return React.createElement(iconsLib[icon], { style: { color: "white" } })
}

// const Icon: FC<IIcon> = ({ icon, className }: IIcon) => {
//     return React.createElement(iconsLib[icon], { className: className })
// }

export default memo(Icon)
