import { toast } from 'react-toastify'

type themeToast = 'error' | 'success' | 'info' | 'warning'

interface notification {
    message: string
    type: themeToast
    timer?: number
}

const showMessage = ({ message, timer, type }: notification) => {
    return toast(message, {
        position: 'top-right',
        autoClose: timer ? timer : 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        type: type,
    })
}

export default showMessage
