/* Map the button role */
export enum ROLE_BUTTON_MAP {
    PRIMARY = 'button--primary',
    SECONDARY = 'button--secondary',
    ACCENT = 'button--accent',
    INFO = 'button--base',
    SUCCESS = 'button--success',
    WARNING = 'button--warning',
    DANGER = 'button--danger',
    NONE = 'button--gray-3',
}

/* Map the text role */
export enum ROLE_PARENT_MAP {
    PRIMARY = 'text-field--primary',
    SECONDARY = 'text-field--secondary',
    ACCENT = 'text-field--accent',
    INFO = 'text-field--base',
    SUCCESS = 'text-field--success',
    WARNING = 'text-field--warning',
    DANGER = 'text-field--danger',
    NONE = 'text-field--gray-3',
}

/* Map the select role */
export enum ROLE_SELECT_MAP {
    PRIMARY = 'select--primary',
    SECONDARY = 'select--secondary',
    ACCENT = 'select--accent',
    INFO = 'select--base',
    SUCCESS = 'select--success',
    WARNING = 'select--warning',
    DANGER = 'select--danger',
    NONE = 'select--gray-3',
}

/* Map the field role */
export enum ROLE_FILE_MAP {
    PRIMARY = 'file-field--primary',
    SECONDARY = 'file-field--secondary',
    ACCENT = 'file-field--accent',
    INFO = 'file-field--base',
    SUCCESS = 'file-field--success',
    WARNING = 'file-field--warning',
    DANGER = 'file-field--danger',
    NONE = 'file-field--gray-3',
}

export enum ROLE_TEXTAREA_MAP {
    PRIMARY = 'textarea-field--primary',
    SECONDARY = 'textarea-field--secondary',
    ACCENT = 'textarea-field--accent',
    INFO = 'textarea-field--base',
    SUCCESS = 'textarea-field--success',
    WARNING = 'textarea-field--warning',
    DANGER = 'textarea-field--danger',
    NONE = 'textarea-field--gray-3',
}
