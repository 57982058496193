/* react */
import { memo, useEffect, useState } from 'react'
/* hooks */
import { useGoogleAnalytics } from '@hooks'
/* layouts */
import { DashboardLayout } from '@layouts'
/* utils */
import { classNames, randomKey } from '@utils'
/* assets */
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineEmail } from 'react-icons/md'
import { HiOutlinePhone } from 'react-icons/hi'
/* index */
import './index.scss'

const QuestionView = () => {
    const [RegisterVisit] = useGoogleAnalytics()

    useEffect(() => {
        RegisterVisit()
    }, [])

    const [accordion, setAccordion] = useState(null)

    const handleAccordion = (i: any) => {
        if (accordion == i) {
            return setAccordion(null)
        }

        setAccordion(i)
    }

    const questions = [
        {
            title: ' ¿Qué es Blocklön?',
            answer: (
                <span>
                    Blocklön es una plataforma que busca transformar la forma en que manejamos el
                    dinero y accedemos a oportunidades financieras, a través de servicios como
                    créditos con garantía en criptomonedas y procesamiento de pagos y cobro con
                    criptomonedas
                </span>
            ),
        },
        {
            title: '¿Cómo puedo ponerme en contacto con el equipo de soporte de Blocklön si tengo preguntas?',
            answer: (
                <span>
                    Nuestro equipo de soporte está disponible a través de la plataforma en línea y
                    nuestras redes sociales para ayudarte en cualquier momento.
                </span>
            ),
        },
        {
            title: '¿Ofrecen programas de recompensas o afiliados en Blocklön?',
            answer: (
                <span>
                    Sí, tenemos programas de recompensas y afiliados que te permiten ganar
                    bonificaciones al referir a nuevos usuarios o comercios a la plataforma.
                </span>
            ),
        },
        {
            title: '¿Cómo se puede acceder al servicio de soporte en caso de necesitar asistencia fuera del horario comercial?',
            answer: (
                <span>
                    Ofrecemos soporte 24/7 a través de nuestra plataforma en línea y correos
                    electrónicos de contacto.
                </span>
            ),
        },
        {
            title: '¿Qué requisitos se necesitan para obtener un crédito con garantía en criptomonedas en Blocklön?',
            answer: (
                <span>
                    Ofrecemos créditos con garantía en criptomonedas que son fáciles de obtener y
                    rápidos de procesar. No se requieren comprobantes de ingresos ni historial
                    crediticio. Lo más importante es tener criptomonedas como garantía para
                    respaldar el préstamo.
                </span>
            ),
        },
        {
            title: '¿Dónde puedo pagar mi crédito?',
            answer: (
                <span>
                    Para la facilidad de nuestros usuarios Blocklön cuenta con múltiples maneras de
                    pago:
                    <ul>
                        <li>Trasferencia electrónica.</li>
                        <li>Pago en efectivo en más de 200 mil puntos de pago.</li>
                        <li>Pago con criptomonedas.</li>
                    </ul>
                </span>
            ),
        },
        {
            title: '¿Qué es LTV?',
            answer: <span>LTV = Valor del préstamo / Valor de la garantía</span>,
        },
        {
            title: '¿Cómo obtengo de regreso mi colateral?',
            answer: (
                <span>
                    100% de manera automática se libera tu colateral, una vez termines de pagar tu
                    crédito, solo tienes que asignar una wallet para liberación.
                </span>
            ),
        },
        {
            title: '¿Cómo puedo solicitar mi crédito?',
            answer: (
                <span>
                    ¡Muy fácil! Sigue estos pasos:
                    <ul>
                        <li>Llena tu solicitud a través de la app.</li>
                        <li>Regístrate.</li>
                        <li>Obtén tu crédito al instante.</li>
                    </ul>
                </span>
            ),
        },
        {
            title: '¿Cuáles son los requisitos para mi crédito?',
            answer: (
                <span>
                    Con Blocklön lo único que necesitas es tu INE vigente, un comprobante de
                    domicilio vigente, tu selfie.
                </span>
            ),
        },
        {
            title: '¿Dónde se recibe el crédito?',
            answer: (
                <span>
                    No te preocupes en Blocklön depositamos el monto de tu crédito en tu cuenta
                    bancaria
                </span>
            ),
        },
        {
            title: '¿Qué ventajas representa un crédito con Blocklön en lugar de una venta de mis criptomonedas?',
            answer: (
                <span>
                    <strong>Impuestos sobre la renta (ISR):</strong> Si tus criptomonedas han
                    aumentado en valor desde que las adquiriste, venderlas generaría una ganancia,
                    que podría estar sujeta a Impuesto sobre la Renta (ISR) hasta un 35%. Al
                    solicitar un préstamo con tus criptomonedas como garantía, no estás realizando
                    una venta, por lo que no incurrirás en un evento imponible. Esto te permite
                    diferir el pago de impuestos hasta que realmente vendas las criptomonedas.
                    (Art.90 LISR).
                </span>
            ),
        },
        {
            title: '¿Qué beneficios obtengo al obtener mi crédito?',
            answer: (
                <span>
                    <strong>Liquidez sin vender activos:</strong> Puedes obtener liquidez (es decir,
                    efectivo) sin tener que vender tus criptomonedas. Esto es útil si crees que el
                    valor de tus criptomonedas aumentará en el futuro y no quieres perder la
                    oportunidad de beneficiarse de posibles apreciaciones.
                </span>
            ),
        },
        {
            title: '¿Necesito liquidez, pero el mercado es bajista?',
            answer: (
                <span>
                    <strong>Evitar la venta en un mercado bajista:</strong> Si crees que el mercado
                    de criptomonedas está en un momento bajista temporal, solicitar un préstamo te
                    permite obtener liquidez sin tener que vender tus criptomonedas a un precio que
                    consideres desfavorable.
                </span>
            ),
        },
        {
            title: '¿Qué sucede si el valor de mis criptomonedas utilizadas como garantía disminuye?',
            answer: (
                <span>
                    Si el valor de tus criptomonedas disminuye, puedes añadir más colateral para
                    mantener tu crédito activo o realizar un pago para reducir tu deuda.
                </span>
            ),
        },
        {
            title: '¿Qué beneficios obtengo al solicitar mi crédito con Blocklön?',
            answer: (
                <span>
                    <ul>
                        <li>
                            No necesitas vender tus criptomonedas: Obtienes la liquidez que
                            necesitas sin tocar tus activos.
                        </li>
                        <li>
                            No pierdes de vista tus activos: Las criptomonedas se almacenan en
                            coldwallets offline únicas, a las que puedes darle seguimiento en todo
                            momento.
                        </li>
                        <li>
                            Difieres impuestos: Si vendes tus cripto, esa ganancia podría estar
                            sujeta a un impuesto sobre la renta.
                        </li>
                        <li>
                            Evitas pérdidas: Tienes la posibilidad de esperar y vender tus cripto
                            más adelante a un precio que consideres favorable.
                        </li>
                    </ul>
                </span>
            ),
        },
        {
            title: '¿Puedo cancelar anticipadamente un crédito en Blocklön?',
            answer: (
                <span>
                    Sí, puedes pagar tu crédito antes de la fecha de vencimiento sin penalizaciones
                    adicionales. Esto te brinda flexibilidad para gestionar tu deuda de manera
                    eficiente.
                </span>
            ),
        },

        {
            title: '¿Cómo funciona el procesamiento de pagos con criptomonedas en Blocklön?',
            answer: (
                <span>
                    En Blocklön, nuestro servicio de procesamiento de pagos con criptomonedas
                    permite a nuestros clientes realizar pagos utilizando sus criptomonedas
                    preferidas, mientras que los comercios pueden recibir moneda nacional. Es una
                    solución segura, eficiente y versátil que simplifica las transacciones
                    financieras.
                </span>
            ),
        },
        {
            title: '¿Cuánto tiempo toma el proceso de aprobación de un crédito en Blocklön?',
            answer: (
                <span>
                    Nuestros créditos son rápidos y fáciles de obtener. En menos de 15 minutos,
                    puedes tener el préstamo aprobado y el dinero en tu cuenta bancaria. Esto es
                    posible gracias a nuestro proceso simplificado y la garantía en criptomonedas.
                </span>
            ),
        },
        {
            title: '¿Cómo se garantiza la transparencia y seguridad en las transacciones financieras con Blocklön?',
            answer: (
                <span>
                    En Blocklön, nos enorgullecemos de ofrecer transacciones confiables y
                    transparentes. Nuestra plataforma utiliza tecnología avanzada y protocolos de
                    seguridad para garantizar la integridad y protección de cada transacción,
                    brindando a nuestros usuarios la confianza necesaria.
                </span>
            ),
        },
        {
            title: '¿Cómo puedo pagar con criptomonedas en Blocklön?',
            answer: (
                <span>
                    Puedes realizar pagos con tus criptomonedas favoritas directamente en nuestra
                    plataforma, y los comercios recibirán la moneda nacional de su elección.
                </span>
            ),
        },
        {
            title: '¿Qué ventajas ofrece el procesamiento de pagos con criptomonedas en Blocklön?',
            answer: (
                <span>
                    El procesamiento de pagos con criptomonedas en Blocklön es eficiente, seguro y
                    versátil, simplificando las transacciones tanto para los usuarios como para los
                    comercios.
                </span>
            ),
        },
        {
            title: '¿Qué tipos de criptomonedas son aceptadas para el procesamiento de pagos en Blocklön?',
            answer: (
                <span>
                    Aceptamos una variedad de criptomonedas populares, incluyendo Bitcoin (BTC),
                    Ethereum (ETH), Usdt (USDT)
                </span>
            ),
        },
        {
            title: '¿Puedo realizar pagos internacionales utilizando el servicio de procesamiento de pagos de Blocklön?',
            answer: (
                <span>
                    No, por el momento estamos trabajando para ofrecerte pagos internacionales,
                    disfruta del procesamiento de pagos actualmente en México.
                </span>
            ),
        },
        {
            title: '¿Cómo se determina el valor de mis criptomonedas en Blocklön?',
            answer: (
                <span>
                    El valor de tus criptomonedas se evalúa en tiempo real según las tasas de cambio
                    actuales y se utiliza como colateral para tu crédito o para tus pagos.
                </span>
            ),
        },
        {
            title: '¿Existe un límite máximo para la cantidad de crédito que puedo obtener en Blocklön?',
            answer: (
                <span>
                    El límite de crédito máximo se basa en el valor de las criptomonedas que
                    utilices como garantía.
                </span>
            ),
        },
        {
            title: '¿Puedo utilizar Blocklön para recibir pagos con crypto en mi negocio?',
            answer: (
                <span>
                    Por supuesto, en Blocklön también servimos a comercios que desean ofrecer nuevos
                    canales de pagos en criptomonedas y desean automatizar el proceso y recibir
                    moneda nacional. Contáctanos para obtener más información.
                </span>
            ),
        },
        {
            title: '¿Qué costos o tarifas están asociados con los servicios de Blocklön?',
            answer: (
                <span>
                    Las tarifas varían según el servicio que elijas. Puedes encontrar información
                    detallada sobre las tarifas en nuestra plataforma, para que tomes decisiones
                    informadas.
                </span>
            ),
        },
        {
            title: '¿Qué respaldo de seguridad existe en caso de problemas con las transacciones en Blocklön?',
            answer: (
                <span>
                    Contamos con un equipo de soporte dedicado que está disponible para ayudarte a
                    resolver cualquier problema o consulta relacionada con tus transacciones.
                </span>
            ),
        },
        {
            title: '¿Qué tan rápido se pueden procesar las transacciones con criptomonedas en Blocklön?',
            answer: (
                <span>
                    Las transacciones con criptomonedas suelen procesarse de manera casi
                    instantánea, lo que significa que puedes realizar pagos o retiros con
                    eficiencia.
                </span>
            ),
        },
    ]

    return (
        <DashboardLayout className="question-view" isBackground={true}>
            <main className="question-view__main">
                <h1>Preguntas frecuentes</h1>

                <div className="question-view__container">
                    <div>
                        {questions.map(
                            ({ answer, title }, index) =>
                                index <= questions.length / 2 && (
                                    <div
                                        className={classNames(
                                            'question-view__card',
                                            accordion == index ? 'question-view__card--active' : ''
                                        )}
                                        key={randomKey().key}
                                    >
                                        <div
                                            className="question-view__card__title"
                                            onClick={() => handleAccordion(index)}
                                        >
                                            <h3>{title}</h3>

                                            <i>
                                                {accordion == index ? (
                                                    <MdKeyboardArrowUp />
                                                ) : (
                                                    <MdKeyboardArrowDown />
                                                )}
                                            </i>
                                        </div>

                                        <div
                                            className={classNames(
                                                'question-view__card__content',
                                                accordion == index
                                                    ? 'question-view__card__content--active'
                                                    : 'question-view__card__content'
                                            )}
                                        >
                                            {answer}
                                        </div>
                                    </div>
                                )
                        )}
                    </div>

                    <div>
                        {questions.map(
                            ({ answer, title }, index) =>
                                index > questions.length / 2 && (
                                    <div
                                        className={classNames(
                                            'question-view__card',
                                            accordion == index ? 'question-view__card--active' : ''
                                        )}
                                        key={randomKey().key}
                                    >
                                        <div
                                            className="question-view__card__title"
                                            onClick={() => handleAccordion(index)}
                                        >
                                            <h3>{title}</h3>

                                            <i>
                                                {accordion == index ? (
                                                    <MdKeyboardArrowUp />
                                                ) : (
                                                    <MdKeyboardArrowDown />
                                                )}
                                            </i>
                                        </div>

                                        <div
                                            className={classNames(
                                                'question-view__card__content',
                                                accordion == index
                                                    ? 'question-view__card__content--active'
                                                    : ''
                                            )}
                                        >
                                            {answer}
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </div>

                <h2>¿Requieres información adicional?</h2>

                <div className="question-view__information">
                    <div className="question-view__content">
                        <i>
                            <MdOutlineEmail />
                        </i>

                        <a href="mailto:soporte@blocklon.com">soporte@blocklon.com</a>
                    </div>

                    <div className="question-view__content">
                        <i>
                            <HiOutlinePhone />
                        </i>

                        <span>+52 8118939984</span>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default memo(QuestionView)
