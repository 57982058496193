import { createReducer } from '@reduxjs/toolkit'
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { defaultAction } from './actions'

import { countrySlice } from './country'

const initialState = {
    message: 'default mesage',
}

export const defaultReducer = createReducer(initialState, {
    [defaultAction.type]: (state, action) => {
        const { payload } = action

        return { ...state, payload }
    },
})

const reducers = {
    defaultReducer,

    country: countrySlice.reducer,
}

const persistConfig = {
    key: 'approot',
    storage: storage,
    timeout: undefined,
    whitelist: ['country'],
}

export const rootReducer = persistCombineReducers(persistConfig, reducers)
export default rootReducer
