/* react */
import { FC, memo, PropsWithChildren } from 'react'
/* utils */
import { classNames } from '@utils'
/* types */
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button } from '@components'
/* assets */
import { LogoCryplon, QuestionIcon } from '@assets'
/* styles */
import './index.scss'

const MobileNavbar: FC<PropsWithChildren<{
    onChangeRight?: any
    onChangeLeft?: any
    iconRight?: any
    iconLeft?: any
    title?: string
    isBackground?: boolean
    className?: string
}>> = ({
    onChangeRight,
    onChangeLeft,
    iconLeft,
    iconRight,
    className,
    title,
    isBackground = false,
    ...rest
}) => {
    return (
        <header
            className={classNames('top-bar', isBackground ? 'top-bar--background' : '', className)}
            {...rest}
        >
            <div className="top-bar__main">
                <a href="https://api.whatsapp.com/send?phone=5218118939984&text=Hola%20Blockl%C3%B6n%2C%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre..." target="_blank">
                    <Button
                        onClick={onChangeRight}
                        role={ROLE_BUTTON_MAP.INFO}
                        variant={VARIANT_BUTTON_MAP.TEXT}
                        className="top-bar__right"
                    >
                        <i><QuestionIcon /></i>
                    </Button>
                </a>

                {title ? (
                    <h3>{title}</h3>
                ) : (
                    <i className="top-bar__icon">
                        <LogoCryplon />
                    </i>
                )}

                <Button
                    onClick={onChangeLeft}
                    role={ROLE_BUTTON_MAP.INFO}
                    variant={VARIANT_BUTTON_MAP.TEXT}
                    className="top-bar__left"
                >
                    <i>{iconLeft}</i>
                </Button>
            </div>
        </header>
    )
}

export default memo(MobileNavbar)
