import { useEffect, useState } from 'react'
import AppRoutes from './App.routes'

function App() {
    const [online, setOnline] = useState<boolean>(navigator.onLine)

    useEffect(() => {
        const handleOnline = () => setOnline(true)
        const handleOffline = () => setOnline(false)

        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    return <main className="App">{!online ? <p>No hay conexion</p> : <AppRoutes />}</main>
}

export default App
