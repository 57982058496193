import axios from 'axios'

export const BASE_URL_SERVER =  'https://api.blocklon.com/api/v1/' //'http://143.198.154.202:8000/api/v1'

export const ApiService = axios.create({
    baseURL: BASE_URL_SERVER,
    headers: {
        'Accept-Language': 'en_US/es_ES',
        'Content-Type': 'application/json',
    },
})
